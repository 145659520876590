var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h2',[_vm._v("Kill Details")]),_c('v-btn',{staticClass:"my-2",attrs:{"to":"/kills"}},[_vm._v(" < Recent Kills ")]),_c('v-row',[_c('v-col',[_c('DataCard',{attrs:{"title":"Killer","loading":!_vm.loaded,"data":[
          ['Name', _vm.kill.killer_name],
          ['Ship', _vm.kill.killer_ship],
          ['Class', _vm.kill.killer_class],
          ['Nuke', _vm.kill.nuke === undefined ? undefined : _vm.kill.nuke ? 'Yes' : 'No']
        ]}})],1),_c('v-col',[_c('DataCard',{attrs:{"title":"Victim","loading":!_vm.loaded,"data":[
          ['Name', _vm.kill.victim_name],
          ['Ship', _vm.kill.victim_ship],
          ['Class', _vm.kill.victim_class],
          ['Value', _vm.kill.victim_cost]
        ]}})],1),_c('v-col',[_c('v-container',[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v("Info")]),_c('v-switch',{attrs:{"label":"Refunded","loading":!_vm.loaded || _vm.savingRefund,"readonly":!_vm.isAdmin || !_vm.kill.refunded_override || _vm.savingRefund},on:{"change":_vm.updateRefundOverride},model:{value:(_vm.kill.refunded),callback:function ($$v) {_vm.$set(_vm.kill, "refunded", $$v)},expression:"kill.refunded"}}),_c('v-checkbox',{attrs:{"label":"Refunded Override","indeterminate":!_vm.loaded || _vm.savingRefund,"readonly":!_vm.isAdmin || _vm.savingRefund,"title":(_vm.kill && _vm.kill.refunded_override_history && _vm.kill.refunded_override_history.length) ? `Refund Override last set by ${_vm.kill.refunded_override_history[_vm.kill.refunded_override_history.length - 1].admin}` : 'Refund never overrided'},on:{"change":_vm.updateRefundOverride},model:{value:(_vm.kill.refunded_override),callback:function ($$v) {_vm.$set(_vm.kill, "refunded_override", $$v)},expression:"kill.refunded_override"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }